/* PURPLE: var(--myLinkHover)  DARKER var(--myBg) LIGHTH var(--mySecondBg)  */
:root {
    --myBg: #121218;
    --mySecondBg: #272c33a8;
    --myThirdBg: #586a7fa8;
    --myWhite: rgb(215, 204, 204);
    --myVeryLightBlue: rgb(191, 196, 217);
    --myNewGreen: rgb(138, 205, 67);
    --myLinkHover: rgb(138, 205, 67);
    --myDisabledButton: #c6b2f0;
    --myAlert: #c66571;
    --myAlertHover: #b1404d;
    --myDisabledAlert: #b16871;
    --myHalfDarkLink: rgba(44, 39, 39, 0.5);
    --myGold: rgb(138, 205, 67);
    --myGoldHover: rgb(125, 192, 55);
    --myFormButton: rgb(94, 190, 83);
    --myFormButtonHover: rgb(72, 184, 59);
    --myFormDisabledButton: rgba(227, 215, 215, 0.502);
    --mySecondContainerBG: rgb(51, 62, 82);
    --progressBarColor: var(--myNewGreen);
}

body {
    margin: 0px !important;
    padding: 0px !important;
    font-weight: 600 !important;
    background-color: var(--myBg) !important;
    color: var(--myWhite) !important;
    overflow: auto !important;
}

html {
    scrollbar-color: var(--myLinkHover) rgb(98, 93, 93);
    scrollbar-width: thin;
}

* {
    font-family: "din-round",sans-serif!important;
    /* font-family: "Georgia", sans-serif!important; */
    font-weight: 600!important;
}

/* updated Scrollbar starts*/
body::-webkit-scrollbar,
.profile_image_modal_box>div::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
    background-color: var(--myWhite);
}

body::-webkit-scrollbar-track,
.profile_image_modal_box>div::-webkit-scrollbar-track {
    background: var(--mySecondBg);
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb,
.profile_image_modal_box>div::-webkit-scrollbar-thumb {
    background-color: var(--myLinkHover);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid var(--myLinkHover);
    /* creates padding around scroll thumb */
}


/* updated Scrollbar ends*/

/* COMMON SETTINGS STARTS */
.logoname:hover {
    color: var(--myLinkHover) !important;
}

/* Buttons with link */
.link-button {
    border-radius: 10px !important;
    min-width: 200px !important;
    margin: 10px !important;
    font-weight: 600 !important;
    background-color: var(--myFormButton) !important;
}

.link-button:hover {
    border-radius: 10px !important;
    min-width: 200px !important;
    margin: 10px !important;
    font-weight: 600 !important;
    background-color: var(--myFormButtonHover) !important;
}

.links {
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
}

.h1-titles {
    color: var(--myLinkHover)
}

.danger-button {
    background-color: var(--myAlert) !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
}

.success-button {
    background-color: var(--myFormButton) !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
}

.danger-button:hover {
    background-color: var(--myAlertHover) !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
}

.success-button:hover {
    background-color: var(--myFormButtonHover) !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
}

.danger-button-disabled {
    background-color: var(--myDisabledAlert) !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    color: var(--myWhite) !important;
    margin-bottom: 1rem !important;
}

/* user account activation Container */
.activation_container {
    background-color: var(--mySecondBg) !important;
    border-radius: 10px !important;
    padding: 10px !important;
}

.red_msg {
    color: var(--myAlert) !important;
}

/* COMMON SETTINGS ENDS */
/* HEADERS STARTS */
/* Main header */
.mainHeader {
    background-color: var(--mySecondBg) !important;
    color: var(--myWhite) !important;
}

.mainHeader_dropdown_item {
    background-color: var(--myBg) !important;
}

.menu_dropdown_container>div:nth-child(3) {
    background-color: var(--mySecondBg) !important;
    color: var(--myWhite);
}

.header-menuItem:hover {
    color: var(--myLinkHover) !important;
}

/* Main footer */
.mainFooter {
    background-color: var(--mySecondBg) !important;
    color: var(--myWhite) !important;
}

.mainFooter .copyright {
    font-weight: 600 !important;
}

.header-links {
    color: var(--myWhite) !important;
}

.header-links:hover {
    color: var(--myNewGreen) !important;
}

.header-links>li:nth-child(1) {
    color: var(--myWhite) !important;
    font-weight: 600 !important;
}

.header-links>li:nth-child(1):hover {
    color: var(--myNewGreen) !important;
    background-color: var(--mySecondBg) !important;
}

/* PROTECTED HEADER STARTS */
/* protected mainHeader dropdown */
#account-menu>div:nth-child(3)>ul {
    background-color: var(--mySecondBg) !important;
    border: 1px solid var(--myWhite) !important;
    padding: 0 !important;
    border-radius: 5px !important;
    color: var(--myWhite) !important;
}

/* protected mainHeader dropdown icons */
.dropdown-icons {
    color: var(--myWhite) !important;
    padding: 2px !important;
}

#menu-appbar .header-links {
    background-color: var(--myBg) !important;
    color: var(--myWhite) !important;
}

.protectedHeaderIcons {
    font-weight: 600 !important;
}

.protectedHeaderIcons:hover {
    color: var(--myNewGreen) !important;
}

/* PROTECTED HEADER ENDS */
/* FORM SETTINGS STARTS */
/*  form submit loading button */
.loadingButton {
    border-radius: 10px !important;
    background-color: var(--myLinkHover) !important;
    color: rgba(0, 0, 0, 0.437) !important;
}

.loadingButton-circular {
    color: rgba(0, 0, 0, 0.437) !important;
}

/* under form link */
.form_links {
    margin-top: 10px
}

/* in login form forgot link */
.LogInForgotLink {
    position: absolute !important;
    margin-top: 8px !important;
    right: 15px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    color: var(--myHalfDarkLink) !important;
}

.LogInForgotLink:hover {
    color: var(--myBg) !important;
}

.ticketFormEndLine {
    position: absolute !important;
    margin-top: auto !important;
    right: 5px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    color: var(--myHalfDarkLink) !important;
}

.ticketFormEndLineMessage {
    position: absolute !important;
    margin-top: 17rem !important;
    right: 1px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    color: var(--myHalfDarkLink) !important;
}

/* form response msg colors (alert/success) */
#response_msg {
    color: var(--myAlert);
    margin: 15px 0px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.response_msg_success {
    color: var(--myNewGreen)!important;
    margin: 15px 0px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.response_success_icon {
    color: var(--myNewGreen) !important;
}

/* Disable browser autoComplete colors*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--myWhite) inset !important;
    box-shadow: 0 0 0 30px var(--myWhite) inset !important;
}

/* form input fields */
.form_input_field {
    border-radius: 10px !important;
    background-color: var(--myWhite) !important;
}

/* after autocompleted */
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 10px!important;
}

/*  form input selected */
.MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline {
    border: 2px solid var(--myFormButton)!important;
    border-radius: 10px!important;
}

/* Normal imput fields */
.form_input_field>div>input {
    font-weight: 600 !important;
}

/* Large text area */
.form_input_field>div>textarea {
    font-weight: 600 !important;
}

.form-divider {
    background-color: rgba(255, 255, 255, 0.469) !important;
    margin: 0.2rem auto 0.4rem auto !important;
}

/* form submit buttons */
.form-button {
    border-radius: 10px !important;
    color: white !important;
    background-color: var(--myFormButton) !important;
    transition-duration: 0.3s!important;
    overflow: hidden!important;
    letter-spacing: 1px!important;
    font-size: 12px!important;
    font-weight: 600 !important;
}

.form-button:hover {
    border-radius: 10px !important;
    color: white !important;
    background-color: #66db5b!important;
    box-shadow: 0px 1px 5px 0px white!important;
    font-weight: 600 !important;
    font-size: 12px!important;
}

.form-button:disabled {
    border-radius: 10px !important;
    background-color: var(--myFormDisabledButton) !important;
    font-weight: 600 !important;
    box-shadow: none!important;
    font-size: 12px!important;
}

.GoodAnswerFormButton {
    border-radius: 10px !important;
    color: white !important;
    background-color: var(--myFormButton) !important;
    transition-duration: 0.3s!important;
    overflow: hidden!important;
    letter-spacing: 2px!important;
    padding: 0.5rem 1rem!important;
    font-weight: 600!important;
}

.GoodAnswerFormButton:hover {
    border-radius: 10px !important;
    color: white !important;
    background-color: #47cb3b!important;
    box-shadow: 0px 1px 10px 0px white!important;
    font-weight: 600 !important;
}


/* FORM SETTINGS ENDS */
/* PAGINATION START */
.pagination {
    margin: 10px 0px!important;
}
.pagination>ul:nth-child(1)>li>button {
    color: white !important;
    margin: 0.2px !important;
}
/* pagination "..." dots color */
.MuiPaginationItem-root {
    color: white !important;
}

.pagination>ul:nth-child(1)>li>button:hover {
    color: var(--myLinkHover) !important;

}

/* pagination active */
.pagination>ul:nth-child(1)>li>button[aria-current="true"] {
    background-color: var(--myLinkHover) !important;
}

.pagination>ul:nth-child(1)>li>button[aria-current="true"]:hover {
    background-color: var(--myLinkHover) !important;
    color: white !important;
}

/* PAGINATION END */
/* TABLE STARTS */
.table_container{
    min-width: auto!important;
    max-width: 1000px!important;
    margin: auto!important;
    background-color: #383c44!important;
    border: 1px solid var(--mySecondBg);
    border-radius: 5px!important;
    color: white!important;
}
.table_header>th {
    background-color: #20232A !important;
    border: 1px solid #262a32;
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
    text-align: center!important;
}
.table_cell {
    color: var(--myWhite)!important;
    text-align: center!important;
    border: 1px solid #262a32!important;
    padding: 5px!important;
}

/* TABLE ENDS */
/* Index navigation and links starts */
.indexLinks {
    color: var(--myWhite) !important;
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    text-transform: capitalize !important;
}

.activeIndexLinks {
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    text-transform: capitalize !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.indexLinks:hover {
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.indexLeftBoxContent {
    background-color: var(--mySecondBg) !important;
    margin: 1rem 0.40rem 5rem 0.5rem !important;
    padding-bottom: 1rem;
    border-radius: 15px !important;
}

.indexRightBoxContent {
    background-color: var(--mySecondBg) !important;
    margin: 1rem 0.4rem 0.5rem 0.40rem !important;
    border-radius: 15px !important;
    padding-bottom: 1rem;
}

.indexProfileAvatar {
    max-width: 100px !important;
    max-height: 100px !important;
    width: auto !important;
    height: auto !important;
    cursor: pointer;
    margin: 2rem auto;
    border: 5px solid var(--myWhite);
}

.indexProfileAvatar:hover {
    max-width: 100px !important;
    max-height: 100px !important;
    width: auto !important;
    height: auto !important;
    cursor: pointer;
    margin: 2rem auto;
    border: 5px solid var(--myLinkHover);
}

.ticketAvatar {
    max-width: 100px !important;
    max-height: 100px !important;
    width: auto !important;
    height: auto !important;
    margin: auto!important;
}

.index_icons {
    margin: 0.7rem auto 0.5rem auto !important;
    max-width: 50px !important;
    max-height: 50px !important;
    width: auto !important;
    height: auto !important;
}

.words_icons {
    margin: 1rem auto 0.5rem auto !important;
    max-width: 65px !important;
    max-height: 65px !important;
    width: auto !important;
    height: auto !important;
}

.index_words_icons {
    max-width: 50px !important;
    max-height: 50px !important;
    width: auto !important;
    height: auto !important;
}

.index_icons_names {
    text-align: center;
    font-weight: 600 !important;
    margin: 0.1rem 0rem !important
}

.index_icons_values {
    text-align: center;
    background-color: var(--myThirdBg);
    margin: auto !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 0.3rem;
    border-radius: 15px !important;
    max-width: 70% !important;
}

.index_rightBox_childrenWordsContainer_beginner {
    background-color: var(--myThirdBg);
    margin: auto !important;
    font-weight: 600 !important;
    padding: 0.5rem !important;
    border-radius: 15px !important;
    border: 1px solid rgba(255, 255, 255, 0);
}

.index_rightBox_childrenWordsContainer_advanced {
    background-color: var(--mySecondContainerBG);
    margin: auto !important;
    font-weight: 600 !important;
    padding: 0.5rem !important;
    border-radius: 15px !important;
    border: 1px solid rgba(255, 255, 255, 0);
}

.index_rightBox_childrenWordsButton_beginner {
    margin: 0 0 0.8rem 0 !important;
    background-color: var(--myVeryLightBlue) !important;
    font-weight: 800 !important;
    color: rgba(0, 0, 0, 0.717) !important;
    letter-spacing: 0 !important;
    box-shadow: 0px 3px 10px var(--myVeryLightBlue) !important;
    min-width: 120px !important;
    min-height: 30px !important;
}

.index_rightBox_childrenWordsButton_beginner:hover {
    background-color: var(--myNewGreen) !important;
}

.index_rightBox_childrenWordsButton_advanced {
    margin: 0 0 0.8rem 0 !important;
    background-color: var(--myVeryLightBlue) !important;
    font-weight: 800 !important;
    color: rgba(0, 0, 0, 0.717) !important;
    letter-spacing: 0 !important;
    box-shadow: 0px 3px 10px var(--myVeryLightBlue) !important;
    min-width: 120px !important;
    min-height: 30px !important;
}

.index_rightBox_childrenWordsButton_advanced:hover {
    background-color: var(--myNewGreen) !important;
}

.index_rightbox_wordsTitle_beginner {
    color: var(--myVeryLightBlue) !important;
    font-weight: 600 !important;
}

.index_rightbox_wordsTitle_advanced {
    color: var(--myVeryLightBlue);
}

.indexUsername {
    margin-top: -1rem !important;
    font-size: larger !important;
    font-weight: 600 !important;
    color: gold;
}

.index_leftBox_muiIcons {
    color: var(--myWhite) !important;
    height: 2rem !important;
    width: 2rem !important;
    margin: 0.1rem 0.4rem;
}

.index_leftBox_muiIcons:hover {
    color: var(--myLinkHover) !important;
    margin: 0.1rem 0.4rem;
}

.index_leftBox_muiIcons_unReadMsg {
    color: var(--myWhite) !important;
    height: 2rem !important;
    width: 2rem !important;
    margin: -1.6rem 0rem 0rem 0rem;
}

.index_leftBox_muiIcons_unReadMsg:hover {
    color: var(--myLinkHover) !important;
    margin: -1.6rem 0rem 0rem 0rem;
}

.index_leftBox_muiIcons_unReadMsg_dot {
    animation: blinker 2s linear infinite !important;
}

/* practice words index starts*/
.practice_words_index_container {
    min-height: 90vh !important;
    width: 100% !important;
}

.practice_words_index_box {
    background-color: var(--mySecondBg) !important;
    margin: 1.5rem auto 0rem auto !important;
    border-radius: 15px !important;
}

.practice_words_play_first_icon {
    color: var(--myAlert);
    margin-left: 5px;
    cursor: pointer;
}

.practice_words_play_second_icon {
    color: var(--myAlert);
    margin-left: 3px;
    cursor: pointer;
}

.practice_words_play_first_icon:hover,
.practice_words_play_second_icon:hover {
    color: var(--myLinkHover);
}

.practice_words_play_rewardInfo {
    animation: rewardFadeIn 1s;
    -webkit-animation: rewardFadeIn 1s;
    -moz-animation: rewardFadeIn 1s;
    -o-animation: rewardFadeIn 1s;
    -ms-animation: rewardFadeIn 1s;
}

@keyframes rewardFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes rewardFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes rewardFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes rewardFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes rewardFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }

/* practice words index ends*/

/* Index navigation and links ends */
/* SETTINGS NAVIGATION AND CONTAINER STARTS */
/* settings menu navigation*/

.Mui-checked + .MuiSwitch-track {
    background-color: var(--myNewGreen) !important;
}

.settings-navigation-header {
    border-bottom: 15px solid var(--myBg) !important;
    margin-top: 1rem!important;
    max-width: 100% !important;
}

.settings-navigation-tab {
    color: var(--myWhite) !important;
    font-weight: 600 !important;
}

.settings-navigation-header>div:nth-child(3)>div>a[aria-selected="true"] {
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
}

.settings-navigation-tab:hover {
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
}

.settings-navigation-tab-indicator {
    background-color: var(--myLinkHover) !important;
}

.settings-content-container {
    padding: 1rem 0.5rem !important;
    margin-top: 2rem !important;
}

.settings-content {
    border-radius: 15px !important;
    text-align: center !important;
    min-height: 85vh !important;
    margin: 0.5rem !important;
    background-color: var(--mySecondBg) !important;
}

/* user Tickets table status starts*/
.ticket_status_zero {
    color: var(--myAlert) !important;
    text-align: center!important;
    border: 1px solid #262a32!important;
}

.ticket_status_one {
    color: var(--myVeryLightBlue) !important;
    animation: blinker 2s linear infinite !important;
    text-align: center!important;
    border: 1px solid #262a32!important;
}

.ticket_status_two {
    color: var(--myAlert) !important;
    text-align: center!important;
    border: 1px solid #262a32!important;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* user Tickets table status ends*/

/* Settings menu support after ticket open start*/
.ticket_container {
    background-color: #181b1f !important;
    border: 1px solid #afaab8 !important;
    border-radius: 15px !important;
    margin: 1rem 0rem !important;
    padding: 0rem 1rem !important;
}

.ticket_left_container {
    margin: 1rem 0rem!important;
}

.ticket_right_container {
    text-align: center!important;
    padding: 1rem!important;
}

.ticket_subject_container {
    background-color: #181b1f !important;
    border: 1px solid #afaab8 !important;
    border-radius: 15px !important;
    margin: 1rem 0rem !important;
    padding: 0rem 1rem !important;
}

.ticket_subject {
    font-weight: bold !important;
    white-space: pre-wrap !important;
    word-wrap: break-word!important;
    margin: 0.3rem auto!important;
    color: gold!important;
}

.ticket_meta {
    /* rgb(152, 138, 219) - rgb(245, 178, 147) */
    font-weight: bold !important;
    margin: 0.5rem auto!important;
}

.ticket_user_information {
    font-weight: bold !important;
    margin: 0.3rem auto!important;
}

.ticket_subject_message {
    margin: 1rem 0.2rem 0rem 0rem !important;
    font-weight: bold !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    color: #ecf2e0 !important;
}

.ticket_message {
    margin: 1rem 0.2rem 0rem 0rem !important;
    font-weight: bold !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    color: #ecf2e0 !important;
}

.ticket_username {
    /* rgb(152, 138, 219) - rgb(245, 178, 147) */
    font-weight: bold !important;
    color: var(--myGold) !important;
    margin: 0.5rem auto!important;
}

.ticket_support_title {
    font-weight: bold !important;
    color: gold!important;
    margin: 0.5rem auto!important;
}

.ticket_divider {
    background-color: var(--myWhite) !important;
}

.ticket_owner_container {
    background-color: #262b30 !important;
    white-space: pre-wrap !important;
    border-radius: 15px !important;
    border: 1px solid #afaab8 !important;
    margin-bottom: 1rem !important;
    padding: 0rem 1rem !important;
}

.ticket_support_container {
    background-color: #22282e !important;
    white-space: pre-wrap !important;
    border-radius: 15px !important;
    border: 1px solid #afaab8 !important;
    margin-bottom: 1rem !important;
    padding: 0rem 1rem !important;
}

.confirm_dialog_container {
    background-color: rgba(11, 9, 9, 0.823) !important;
    text-align: center !important;
    padding: 0.5rem !important;
    color: white !important;
}

/* Settings menu support after ticket open end*/
/* SETTINGS NAVIGATION AND CONTAINER ENDS */

/* ALL SWITCHES  STARTS*/
/* line color */
.options_switch>span:nth-child(2) {
    /* background-color: hsl(283, 36%, 16%)!important; */
    opacity: 0.5 !important;
}

.options_switch>span:nth-child(1) {
    color: var(--myLinkHover) !important;
}

/* ALL SWITCHES  ENDS*/


/* Support menu */
.support_messages_selector {
    margin-bottom: 5px !important;
}

.support_messages_selector>div>div:nth-child(1) {
    padding: 0px;
    padding-left: 10px !important;
    color: var(--myWhite) !important;
}

.support_messages_selector>div {
    border: 2px solid var(--myGold) !important;
    border-radius: 15px!important;
}

.support_messages_selector>div>svg {
    color: var(--myWhite) !important;
}

/* Profile IMAGE modal box  starts*/
.profile_image_avatar {
    max-width: 150px !important;
    max-height: 150px !important;
    width: auto !important;
    height: auto !important;
    cursor: pointer;
    margin: 1rem auto;
    border: 5px solid var(--myWhite);
}

.profile_image_avatar:hover {
    max-width: 150px !important;
    max-height: 150px !important;
    width: auto !important;
    height: auto !important;
    cursor: pointer;
    margin: 1rem auto;
    border: 5px solid var(--myAlert);
}

.profile_image_modal_box {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: var(--myBg) !important;
    border: 5px solid var(--myLinkHover) !important;
    border-radius: 10px !important;
    padding: 0.1rem;
}

.profile_image_modal_box>div {
    padding: 0px !important;
    margin: auto !important;
    max-width: 70vw;
    max-height: 70vh;
    overflow: auto;
}


.profile_image_modal_box_tablist {
    margin-bottom: 10px !important;
}

.profile_image_modal_box_tablist>div>div>button {
    padding: 0px !important;
    color: var(--myWhite) !important;
    font-weight: 600 !important;
}

.profile_image_modal_box_tablist>div>div>button[aria-selected="true"] {
    color: var(--myLinkHover) !important;
    font-weight: 600 !important;
}

.input-hidden {
    position: absolute;
    left: -9999px;
}

.avatars_grid_item {
    margin: auto !important;
    padding: 0rem 0.2rem;
}

input[type=radio]:checked+label>img {
    box-shadow: 0px 0px 5px 5px var(--myVeryLightBlue) !important;
}

input[type=radio]+label>img:hover {
    box-shadow: 0px 0px 5px 5px var(--myNewGreen) !important;
    cursor: pointer;
}

/* Stuff after this is only to make things more pretty */
input[type=radio]+label>img {
    border: 2px solid rgba(255, 255, 255, 0);
    height: 100px;
    width: 100px;
    margin: 4px 0px;
}

/* Profile IMAGE modal box  ends*/